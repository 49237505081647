import get from 'lodash/get';
import { graphql } from 'gatsby';
import Img from 'gatsby-image'
import Layout from '../components/layout';
import React from 'react';
import styles from './contact.module.css';

import { Field, Control, Input, Button, Textarea } from 'rbx';

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

class ContactForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      subject: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit = e => {
    const { name, email, message } = this.state;
    if (name && email && message) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...this.state })
      })
        .then(() => alert("Success!"))
        .catch(error => alert(error));

      e.preventDefault();
    }
    else {
      alert('Please fill out all required fields');
    }
  };

  resetForm() {
    this.setState({
      name: '',
      email: '',
      message: '',
      subject: '',
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { name, email, message, subject } = this.state;
    const [contactInfo] = get(this, 'props.data.allContentfulContactPage.edges')

    return (
      <Layout location={this.props.location}>
        <div className={styles.contactHeader}>{contactInfo.node.headerText}</div>
        <ul className="contact-list">
          <li className={styles.listImage}>
            <Img
                className={styles.roundImage}
                fluid={contactInfo.node.contactImage.fluid}
            />
          </li>
          <li className={styles.listFields}>
          <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
                <Field>
                    <Control>
                        <Input
                        name="name"
                        type="text"
                        placeholder={contactInfo.node.namePlaceholder}
                        value={name}
                        onChange={this.handleChange}
                        required
                        />
                    </Control>
                </Field>
                <Field>
                    <Control>
                        <Input
                        name="email"
                        type="email"
                        placeholder={contactInfo.node.emailPlaceholder}
                        value={email}
                        onChange={this.handleChange}
                        required
                        />
                    </Control>
                </Field>
                <Field>
                    <Control>
                        <Input
                        name="subject"
                        type="text"
                        placeholder={contactInfo.node.subjectPlaceholder}
                        value={subject}
                        onChange={this.handleChange}
                        />
                    </Control>
                </Field>
                <Field>
                    <Control>
                        <Textarea
                        name="message"
                        placeholder={contactInfo.node.messagePlaceholder}
                        value={message}
                        onChange={this.handleChange}
                        required
                        />
                    </Control>
                </Field>
                <Field>
                    <Control>
                        <Button color="dark">Send</Button>
                    </Control>
                </Field>
            </form>
          </li>
        </ul>
      </Layout>
    );
  }
}

export default ContactForm;

export const pageQuery = graphql`
  query ContactPageIndexQuery {
    allContentfulContactPage {
      edges {
        node {
          headerText
          namePlaceholder
          emailPlaceholder
          subjectPlaceholder
          messagePlaceholder
          contactImage {
            fluid(resizingBehavior: NO_CHANGE, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`